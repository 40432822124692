.two-factor-enabled {
  display: block;
  padding-bottom: 15px;
  max-width: 648px;

  &__qr-code-block {
    min-height: 250px;
    min-width: 250px;
  }

  a {
    padding-left: 3px !important;
    padding-right: 3px !important;
    min-width: auto !important;
  }
}
