@import "./message-legend/message-legend.component";
@import "./unit-accesses/unit-accesses.component";
@import "./products/products.component";

.handbooks {
  display: block;
  height: 100%;
}

.add-edit-handbooks {
  display: block;
  position: relative;
}

handbook-mat-svg-icon-cell {
  display: block;
}

.atp-dynamic-table-full-crud__handbooks-column_addtitle,
.atp-dynamic-table-full-crud__handbooks-column_edittitle {
  min-width: 200px;
}

td.atp-dynamic-table-full-crud__handbooks-column_icon {
  text-align: center;
}

.handbooks-list {
  display: block;
}
