/* You can add global styles to this file, and also import other style files */
// @import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import "./reset";

@import "~@angular/material/theming";

@import "./vars";
@import "./mixins";
// $atp-theme-primary: mat-palette($mat-indigo);
// $atp-theme-accent: mat-palette($mat-pink);
// $atp-theme-warn: mat-palette($mat-red);
// $atp-theme: mat-light-theme($atp-theme-primary, $atp-theme-accent, $atp-theme-warn);

$light-theme-background: (
  status-bar: map_get($mat-grey, 300),
  app-bar: map_get($mat-grey, 100),
  background: map_get($mat-grey, 50),
  hover: map-get($primary-pallete, 50),
  // TODO(kara): check style with Material Design UX
  card: white,
  dialog: white,
  disabled-button: rgba(black, 0.12),
  raised-button: white,
  focused-button: $dark-focused,
  selected-button: map_get($mat-grey, 300),
  selected-disabled-button: map_get($mat-grey, 400),
  disabled-button-toggle: map_get($mat-grey, 200),
  unselected-chip: map_get($mat-grey, 300),
  disabled-list-option: map_get($mat-grey, 200),
);

$light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba(black, 0.26),
  disabled-text: $dark-disabled-text,
  hint-text: #bdbdbd,
  secondary-text: #bdbdbd,
  icon: rgba(black, 0.54),
  icons: rgba(black, 0.54),
  text: $custom-dark-primary-text,
  slider-min: rgba(black, 0.87),
  slider-off: rgba(black, 0.26),
  slider-off-active: rgba(black, 0.38),
);

@function custom-light-theme($primary, $accent, $warn: mat-palette($mat-red)) {
  @return (
    primary: $primary,
    accent: $accent,
    warn: $warn,
    is-dark: false,
    foreground: $light-theme-foreground,
    background: $light-theme-background
  );
}

$custom-theme-primary: mat-palette($primary-pallete);
$custom-theme-accent: mat-palette($accent-pallete);
$custom-theme-warn: mat-palette($warn-pallete);

$theme: custom-light-theme($custom-theme-primary, $custom-theme-accent, $custom-theme-warn);

$typography: mat-typography-config(
  $font-family: '"Source Sans Pro", sans-serif',
  $display-4: mat-typography-level(112px, 112px, 700, $letter-spacing: -0.05em),
  $display-3: mat-typography-level(56px, 56px, 700, $letter-spacing: -0.02em),
  $display-2: mat-typography-level(45px, 48px, 700, $letter-spacing: -0.005em),
  $display-1: mat-typography-level(36px, 45px, 700, $letter-spacing: -0.02em),
  $headline: mat-typography-level(36px, 45px, 700, $letter-spacing: -0.02em),
  $title: mat-typography-level(32px, 40px, 600, $letter-spacing: -0.24em),
  $subheading-2: mat-typography-level(28px, 35px, 600, $letter-spacing: -0.24em),
  $subheading-1: mat-typography-level(26px, 33px, 600, $letter-spacing: -0.24em),
  $body-1: mat-typography-level(16px, 20px, 400),
  $body-2: mat-typography-level(16px, 20px, 700),
  $caption: mat-typography-level(12px, 16px, 400),
  $button: mat-typography-level(16px, 24px, 400, $letter-spacing: -0.154px),
  // Line-height must be unit-less fraction of the font-size.
  $input: mat-typography-level(inherit, 1.125, 400),
);

@include mat-core($typography);
@include angular-material-theme($theme);

.mat-button-base:not(.mat-icon-button) {
  .mat-icon {
    margin-right: 10px;
  }
}

.mat-icon-button {
  .mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

a.mat-button-base {
  color: #3f51b5;
}

.mat-button,
.mat-stroked-button,
.mat-raised-button,
.mat-flat-button {
  padding-left: 24px !important;
  padding-right: 24px !important;
  border-radius: 3px !important;
  line-height: 40px !important;
}

.mat-stroked-button:not(.mat-button-disabled),
.mat-stroked-button.mat-button-disabled {
  border-color: #e0e0e0;
}

.mat-raised-button:not([class*="mat-elevation-z"]) {
  box-shadow: 0px 3px 10px -2px rgba(0, 0, 0, 0.2);
}

.mat-form-field {
  &_small {
    font-size: 14px;
  }

  .mat-form-field-subscript-wrapper {
    font-size: 12px;
  }
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.8em 0 0.9em 0;
}

.mat-form-field-appearance-outline .mat-form-field-flex {
  padding: 0 0.875em;
}

.mat-form-field-infix {
  border-top: 0.3em solid transparent;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #bdbdbd;
}

.mat-focused .mat-form-field-required-marker {
  color: #bdbdbd;
}

.mat-form-field-appearance-outline .mat-form-field-prefix,
.mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 0.35em !important;

  .mat-icon {
    color: #616161;
  }
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-1.2em) scale(0.8);
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
  border-width: 1px !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-start {
  border-radius: 3px 0 0 3px !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-radius: 0 3px 3px 0 !important;
}

.mat-form-field-wrapper {
  padding-bottom: 1.1875em;
}

.mat-form-field {
  max-width: 648px;
  width: 100%;
}

.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: none !important;
}

.mat-select-arrow {
  color: #616161;
}

textarea.mat-input-element {
  min-height: 86px;
}

.mat-option {
  font-size: 16px;
  line-height: 130%;

  &-text {
    &-colored {
      color: map-get($map: $primary-pallete, $key: 800);
    }
  }
}

.mat-option:not(.mat-option-disabled) {
  transition: background-color, color 0.2s linear;
}

.mat-option:hover:not(.mat-option-disabled) {
  color: map-get($map: $primary-pallete, $key: 800);
}

.mat-drawer-container {
  background-color: transparent !important;
}

.mat-drawer {
  background-color: transparent;
}

.mat-drawer-side {
  border-right: 0;
}

.mat-checkbox-inner-container {
  width: 18px !important;
  height: 18px !important;
  margin-right: 15px !important;
}

.mat-checkbox-inner-container-no-side-margin {
  margin-right: 0 !important;
}

.mat-checkbox-frame {
  border-color: map-get($primary-pallete, 500);
}

.mat-tab-label {
  opacity: 1 !important;
}

.mat-tab-label-content {
  color: $custom-dark-primary-text !important;
}

.mat-paginator {
  font-size: 14px;

  .mat-paginator-page-size-select {
    width: 39px;

    .mat-select-value {
      color: #616161;
      font-size: 14px;
      text-align: end;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0.8em;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-material.css";

.ag-theme-material {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px;
  line-height: 16px;
  color: $custom-dark-primary-text;

  .ag-header-cell-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: $custom-dark-primary-text;
    overflow: visible;
    text-overflow: clip;
    white-space: normal;
  }

  .ag-row {
    min-height: 64px;
  }

  .ag-cell {
    line-height: 1.2em;
    padding: 10px;
    color: $custom-dark-primary-text;
  }

  .ag-cell-inline-editing {
    border-color: transparent !important;
    box-shadow: none;
    background-color: transparent;
  }

  .ag-has-focus {
    .ag-cell-inline-editing {
      border-color: transparent !important;
    }
  }

  .ag-ltr {
    .ag-has-focus {
      .ag-cell-focus:not(.ag-cell-range-selected.ag-cell-last-left-pinned) {
        border-color: transparent !important;
      }
    }
  }

  .ag-header-cell {
    padding: 22px 12px 10px;
  }

  .ag-center-cols-container {
    min-width: 100%;
  }

  .ag-header-group-cell {
    padding: 0 12px;
  }

  .ag-header-container {
    min-width: 100%;
  }

  .ag-header-row {
    min-width: 100%;
  }

  .ag-header-row:not(:first-child) {
    border-top: 1px solid;
    border-top-color: var(--ag-border-color, #e2e2e2);
  }

  .ag-header-cell {
    border-top: 0 !important;
  }
}

.ag-theme-material .ag-cell.ag-cell-inline-editing {
  height: 100%;
}

// TODO: не видно кнопку растягивания на textarea
// ::-webkit-scrollbar-button {
//   background-repeat: no-repeat;
//   width: 9px;
//   height: 2px;
// }

// ::-webkit-scrollbar-track {
// }

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0px;
  border-radius: 0px;
  background-color: #d3d9de;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #d3d9de;
}

::-webkit-resizer {
  background-repeat: no-repeat;
  width: 9px;
  height: 0px;
}

::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

.material-icons {
  user-select: none;

  &.mat-icon {
    height: 1em;
    width: 1em;
  }
}

body {
  margin: 0;
  background-color: #f6f6f6;
  font-family: "Source Sans Pro", sans-serif;
  overflow-x: hidden;
}

h1,
.h1 {
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: -0.02em;
}

h2,
.h2 {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.24px;
}

h3,
.h3 {
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;
  letter-spacing: -0.24px;
}

h4,
.h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 33px;
  letter-spacing: -0.24px;
}

h5,
.h5 {
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.24px;
}

h6,
.h6 {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.24px;
}

mark {
  background-color: #d3d9de;
}

p {
  font-size: 16px;
  line-height: 20px;
  color: $custom-dark-primary-text;
}

small {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
}

.text {
  font-size: 16px;
  line-height: 20px;
  color: $custom-dark-primary-text;
}

.link{
  color: #73b600;
  line-height: 1.5;
}

ul {
  li {
    position: relative;
    padding-left: 28px;
  }

  li + li {
    margin-top: 32px;
  }

  li::before {
    content: "";
    position: absolute;
    top: 7px;
    left: 0;
    height: 8px;
    width: 8px;
    background-color: map-get($map: $primary-pallete, $key: 700);
    border-radius: 50%;
  }

  ul {
    li {
      margin-top: 32px;
    }

    li::before {
      content: "";
      top: 10px;
      border-radius: 0;
      width: 15px;
      height: 2px;
    }
  }
}

ol {
  counter-reset: item;
  margin-bottom: 16px;

  li {
    position: relative;
    padding-left: 38px;
  }

  li + li {
    margin-top: 16px;
  }

  li::before {
    content: counters(item, ".") ". ";
    counter-increment: item;
    position: absolute;
    left: 0;
    color: map-get($map: $primary-pallete, $key: 700);
  }

  ol li {
    padding-left: 44px;
  }

  ol {
    margin-top: 16px;

    li {
      margin-top: 16px;
    }
  }
}

.mat-chip {
  font-weight: normal !important;
}

.mat-standard-chip {
  border-radius: 3px !important;
  margin: 0 !important;
  padding: 9px 12px !important;

  .mat-chip-remove {
    color: #616161 !important;
    opacity: 1 !important;
  }
}

// .custom-material-button {
//     @include mat-button-theme(
//         mat-light-theme(mat-palette($mat-teal), mat-palette($mat-light-green), mat-palette($mat-orange))
//     );
// }

@import "app/atp-core/atp-core";

.atp-preloader-container {
  background-color: transparent;
}

.list {
  padding-top: 24px;
  display: flex;
  flex-wrap: wrap;
  margin-top: -20px;
  margin-left: -52px;
  max-width: 1100px;

  &__item {
    display: block !important;
    width: 460px;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.154px;
    background-color: white;
    color: $custom-dark-primary-text !important;
    border: 0 !important;
    margin-top: 20px !important;
    margin-left: 52px !important;
    padding-top: 9px !important;
    padding-bottom: 9px !important;
  }
}

// TODO: Styles
@import "app/app.component";
@import "app/main/main.component";
@import "app/components/nav-menu/nav-menu.component";
@import "app/login/login.component";
@import "app/handbooks/handbooks.component";
@import "app/profile/profile.component";
@import "app/users/users.component";
@import "app/lots/lots.component";
@import "app/lot-templates/lot-templates.component";
@import "app/subscription-section-products/subscription-section-products.component";
@import "app/messages/messages.component";
@import "app/components/working-days/working-days.component";
// @import "app/instruction/instruction.component";
@import "app/settings/settings.component";
@import "app/error404/error404.component";

.cdk-overlay-pane {
  @media screen and (max-width: 1024px) {
    max-width: 90vw !important;
  }
}
