.nav-menu {
  display: block;
  padding: 24px;
  height: 100%;

  @media screen and (max-width: 1024px) {
    padding: 0;
    height: 100%;
  }

  &__container {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 22px 34px 22px;
    width: 100%;
    height: 100%;
    background-color: white;
    box-shadow: -7px 15px 20px rgba(0, 0, 0, 0.05);
    width: 289px;
    border-radius: 3px;

    @media screen and (max-width: 1024px) {
      height: 100%;
    }

    &_transitions {
      transition: width 0.2s linear;

      .nav-menu {
        &__link-text {
          transition-property: padding-left, opacity;
          transition-duration: 0.2s;
          transition-timing-function: linear;
        }

        &__logo-container {
          transition: opacity 0.2s linear;
        }

        &__toggle {
          margin-right: -12px;
        }

        &__info {
          /*height: 84px;*/
          margin-top: auto;
          transition: opacity 0s linear;
          transition-delay: 0.2s;
        }
      }
    }

    &_animate {
      width: 142px;

      .nav-menu {
        &__link {
          overflow: hidden;
        }

        &__link-text {
          opacity: 0;
          padding-left: 20px;
        }

        &__logo-container {
          opacity: 0;
        }

        &__info {
          opacity: 0;
          height: 84px;
          transition: none;
        }

        &__toggle {
          margin-right: 16px;
        }
      }
    }
  }

  &__logo {
    width: 100%;

    &-container {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 47px;
      height: 51px;
      justify-content: center;
    }
  }

  &__info {
    &-company {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #616161;
      margin-bottom: 5px;
    }

    &-text {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.02em;
      color: #bdbdbd;
    }
  }

  &__toggle {
    &-container {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 13px;
    }
  }

  &__link {
    width: 100%;
    text-align: start;
    border-radius: 3px;
    line-height: 35px !important;
    display: block !important;

    .mat-button-wrapper {
      display: inline-block;
      width: 100%;
      text-align: start;
      color: #333333;
    }

    &-text {
      white-space: nowrap;
    }

    &s {
      //margin-bottom: 76px;
      overflow-y: auto;
      overflow-x: hidden;

      &-separator {
        margin: 16px;
        width: calc(100% - 32px);
        height: 1px;
        background: #e0e0e0;
      }
    }

    &-icon {
      margin-right: 8px;
    }

    &_active {
      background-color: map-get($map: $primary-pallete, $key: 50);

      .mat-button-wrapper {
        color: map-get($map: $primary-pallete, $key: 800);
      }
    }
  }

  &__link + &__link {
    margin-top: 6px;
  }
}
