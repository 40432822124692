.atp-range-date-time-picker_datetime,
.atp-range-date-time-picker_rangedatetime {
    .mat-input-element,
    input {
        width: 110px;
    }
}

.atp-range-date-time-picker_datefulltime,
.atp-range-date-time-picker_rangedatefulltime {
    .mat-input-element,
    input {
        width: 145px;
    }
}

.atp-range-date-time-picker_date,
.atp-range-date-time-picker_rangedate {
    .mat-input-element,
    input {
        width: 80px;
    }
}

.atp-range-date-time-picker_time,
.atp-range-date-time-picker_rangetime {
    .mat-input-element,
    input {
        width: 40px;
    }
}

.atp-range-date-time-picker_fulltime,
.atp-range-date-time-picker_rangefulltime {
    .mat-input-element,
    input {
        width: 65px;
    }
}

atp-range-date-time-picker {
    display: flex;
    width: fit-content;
}

.atp-date-time-picker__separator {
    margin: 0 10px;
}

.atp-date-time-picker__input-to {
    text-align: right;
}

atp-date-time-picker-popup {
    display: block;
    width: 330px;
    box-shadow: 0 2px 24px rgba(168, 175, 196, 0.5);

    @media screen and (max-height: 800px) {
        width: 290px;
    }

    @media screen and (max-height: 650px) {
        width: 250px;
    }
}

.atp-date-time-picker-popup__container {
    background-color: white;
    padding: 20px;
    position: relative;
    z-index: 1;

    @media screen and (max-height: 800px) {
        padding: 16px;
    }

    @media screen and (max-height: 650px) {
        padding: 12px;
    }
}

.atp-date-time-picker__top-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .mat-stroked-button {
        margin-top: 1.5px;
        width: 40%;
    }

    button {
        padding: 0 !important;
        width: 29%;

        @media screen and (max-height: 800px) {
            font-size: 15px;
            height: 34px;
            line-height: 34px !important;
        }

        @media screen and (max-height: 650px) {
            font-size: 12px;
            height: 30px;
            line-height: 30px !important;
        }
    }
}

.atp-date-time-picker__time {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;

    @media screen and (max-height: 650px) {
        margin-top: 18px;
    }

    input {
        text-align: center;
    }

    .mat-form-field {
        .mat-form-field-wrapper {
            padding-bottom: 0;
        }

        .mat-form-field-underline {
            display: none;
        }
    }
}

.atp-date-time-picker__time-item {
    display: flex;
    flex-grow: 0;
    width: 32%;

    .mat-form-field {
        width: calc(100% - 30px);

        .mat-form-field-label {
            text-overflow: clip;
        }
    }
}

.atp-date-time-picker__time-item-actions {
    display: flex;
    flex-direction: column;
    height: fit-content;

    button {
        width: 30px;
        height: 30px;
        line-height: 30px;

        @media screen and (max-height: 800px) {
            width: 24px;
            height: 24px;
            line-height: 24px;
        }

        @media screen and (max-height: 650px) {
            width: 20px;
            height: 20px;
            line-height: 20px;

            .mat-icon {
                font-size: 20px !important;
                line-height: 20px !important;
            }
        }
    }
}

.atp-date-time-picker__close-container {
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: transparent;
}

.atp-date-time-picker__calendar {
    margin: 20px 0;
    width: 100%;
    height: 300px;

    @media screen and (max-height: 800px) {
        margin: 15px 0;
        width: 100%;
        height: 240px;
    }

    @media screen and (max-height: 650px) {
        margin: 12px 0;
        width: 100%;
        height: 200px;
    }
}

atp-date-time-picker-year-calendar,
atp-date-time-picker-month-calendar,
atp-date-time-picker-day-calendar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
}

atp-date-time-picker-year-calendar {
    .mat-stroked-button {
        width: 24%;
        min-width: auto;
        height: 24%;
        padding: 0 !important;

        @media screen and (max-height: 800px) {
            font-size: 15px;
        }

        @media screen and (max-height: 650px) {
            font-size: 12px;
        }
    }
}

atp-date-time-picker-month-calendar {
    .mat-stroked-button {
        width: 32%;
        min-width: auto;
        height: 24%;
        padding: 0 !important;

        @media screen and (max-height: 800px) {
            font-size: 15px;
        }

        @media screen and (max-height: 550px) {
            font-size: 12px;
        }
    }
}

atp-date-time-picker-day-calendar {
    .mat-stroked-button {
        width: calc(100% / 7 - 1%);
        min-width: auto;
        height: calc(100% / 7 - 1%);
        line-height: calc(100% / 7 - 1%) !important;
        padding: 0 !important;

        @media screen and (max-height: 800px) {
            font-size: 15px;
        }

        @media screen and (max-height: 650px) {
            font-size: 12px;
        }
    }
}
