.add-edit-data-lot-templates {
  display: block;

  &__title {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.24px;
    color: $custom-dark-primary-text;
    margin-bottom: 16px;
  }

  &__group + &__group {
    margin-top: 32px;
  }

  &__property {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &-choose {
      width: calc(100% / 3 - 8px) !important;
    }

    &-value {
      margin-left: 8px;
      width: calc(100% / 3 - 8px);
    }

    &-checkbox-container {
      margin-top: 13px;
      margin-left: 8px;
      padding: 0 7px;
      width: calc(100% / 3 - 80px);
    }

    &-button-container {
      margin-top: 8px;
      width: 40px;
    }

    .atp-dynamic-form__form-field_checkbox {
      margin-top: 13px;
    }
  }
}
