.atp-dialog-container {
  .profile {
    .atp-dynamic-form__content {
      padding-top: 4px;
    }
  }
}

.profile {
  display: block;
  height: 100%;

  .atp-dynamic-form {
    &__container {
      &_worker {
        @include changeGroups("atp-dynamic-form__content");
      }
    }

    &__actions {
      width: 100%;
      margin-left: 0;
    }

    &__content {
      margin: 0;
      width: 100%;
      height: 100%;
      padding: 24px 0 0 0;
      background-color: transparent;
      box-shadow: none;
    }

    &__tab-item-content {
      margin-bottom: 6px;
    }
  }

  .atp-dynamic-form__title + .atp-dynamic-form__content {
    padding-top: 0;
  }

  @include changeGroups("atp-dynamic-form__tab-item-content");
}

@import "./two-factor-enabled/two-factor-enabled.component";
