.atp-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &__text {
    flex-grow: 1;
    text-align: start;
  }

  &__before-icon {
    flex-grow: 0;
    margin-right: 10px;
  }

  &__after-icon {
    flex-grow: 0;
    margin-left: 10px;
  }
}
