$custom-dark-primary-text: #333333;
$custom-light-primary-text: #ffffff;

$primary-pallete: (

  900: #142810,
  800: #28501F,
  700: #356A2A,
  600: #49923A,
  500: #649C58,
  400: #86B37D,
  300: #A8C9A1,
  200: #B9D5B3,
  100: #DBECD8,
  50: #ECF7EA,

  A100: #DBECD8,
  A200: #A8C9A1,
  A400: #649C58,
  A700: #28501F,

  //50: #ecf5de,
  //100: #d3ebaf,
  //200: #abd769,
  //300: #97cf43,
  //400: #83c60d,
  //500: #73b600,
  //600: #6eae00,
  //700: #67a400,
  //800: #528f00,
  //900: #407000,
  //A100: #d3ebaf,
  //A200: #97cf43,
  //A400: #73b600,
  //A700: #528f00,
  contrast: (
    50: $custom-dark-primary-text,
    100: $custom-dark-primary-text,
    200: $custom-dark-primary-text,
    300: $custom-dark-primary-text,
    400: $custom-light-primary-text,
    500: $custom-light-primary-text,
    600: $custom-light-primary-text,
    700: $custom-light-primary-text,
    800: $custom-light-primary-text,
    900: $custom-light-primary-text,
    A100: $custom-dark-primary-text,
    A200: $custom-dark-primary-text,
    A400: $custom-dark-primary-text,
    A700: $custom-dark-primary-text,
  ),
);

$accent-pallete: (
  50: #eee5f9,
  100: #d4c0ef,
  200: #b795e5,
  300: #9a69db,
  400: #8345d3,
  500: #6b1ccb,
  600: #6117c5,
  700: #520abc,
  800: #4300b6,
  900: #2500ac,
  A100: #d4c0ef,
  A200: #9a69db,
  A400: #6b1ccb,
  A700: #4300b6,
  contrast: (
    50: $custom-dark-primary-text,
    100: $custom-dark-primary-text,
    200: $custom-dark-primary-text,
    300: $custom-light-primary-text,
    400: $custom-light-primary-text,
    500: $custom-light-primary-text,
    600: $custom-light-primary-text,
    700: $custom-light-primary-text,
    800: $custom-light-primary-text,
    900: $custom-light-primary-text,
    A100: $custom-dark-primary-text,
    A200: $custom-light-primary-text,
    A400: $custom-light-primary-text,
    A700: $custom-light-primary-text,
  ),
);

$warn-pallete: (
  50: #ffeaed,
  100: #ffc9cf,
  200: #f59393,
  300: #ec6769,
  400: #f63f43,
  500: #fb2424,
  600: #ec1225,
  700: #da001f,
  800: #ce0017,
  900: #c00007,
  A100: #ffc9cf,
  A200: #ec6769,
  A400: #fb2424,
  A700: #ce0017,
  contrast: (
    50: $custom-dark-primary-text,
    100: $custom-dark-primary-text,
    200: $custom-dark-primary-text,
    300: $custom-dark-primary-text,
    400: $custom-dark-primary-text,
    500: $custom-light-primary-text,
    600: $custom-light-primary-text,
    700: $custom-light-primary-text,
    800: $custom-light-primary-text,
    900: $custom-light-primary-text,
    A100: $custom-dark-primary-text,
    A200: $custom-dark-primary-text,
    A400: $custom-light-primary-text,
    A700: $custom-light-primary-text,
  ),
);

$max-width: 1683px;
$header-height: 66px;
