@mixin changeGroups($baseClass) {
  .#{$baseClass} > .atp-dynamic-form__group {
    background-color: white;
    max-width: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
    border-radius: 3px;
    margin: 3px 3px 8px;
    width: calc(100% - 6px);
    padding: 32px 40px 0;

    @media screen and (max-width: 600px) {
      padding: 16px 16px 0;
    }
  }

  .#{$baseClass} > .atp-dynamic-form__group > .atp-dynamic-form__group-fieldset {
    margin: 5px 0 0;
    padding-left: 0;
    padding-right: 0;
    border: 0;
  }

  .#{$baseClass} > .atp-dynamic-form__group_no-heading {
    padding-top: 12px;
  }

  .#{$baseClass} > .atp-dynamic-form__group_no-heading > .atp-dynamic-form__group-fieldset {
    padding-bottom: 12px;
  }

  .#{$baseClass}
    > .atp-dynamic-form__group
    > .atp-dynamic-form__group-fieldset
    > .atp-dynamic-form__group-fieldset-container
    > .atp-dynamic-form__group-fieldset-label {
    font-size: 22px;
    line-height: 28px;
  }

  .#{$baseClass}
    > .atp-dynamic-form__group
    > .atp-dynamic-form__group-fieldset
    > .atp-dynamic-form__group-fieldset-container
    > .atp-dynamic-form__form-field_checkbox:first-child {
    margin-top: 14px;
  }

  .#{$baseClass} > .atp-dynamic-form__group + .atp-dynamic-form__group {
    margin-top: 8px;
  }
}
