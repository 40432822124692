.atp-movement-tree {
  display: block;
  position: relative;
  height: 100%;

  &__button {
    display: flex;
    align-items: center;

    &-internal-icon {
      margin-right: 10px;
    }

    &_folder {
      min-width: 0;
    }

    &_root {
      align-self: center;
    }
  }

  &__progress-bar {
    margin-left: 50px !important;
    position: absolute !important;
    bottom: 0px;
    width: calc(100% - 50px) !important;
  }

  &__node {
    position: relative;
  }
}
