.atp-dialog-container {
  & > .atp-history {
    width: 80vw !important;
    height: 100% !important;
    padding: 0 24px;

    @media screen and (max-width: 1024px) {
      width: 90vw !important;
    }

    .atp-dynamic-form__title {
      display: block;
    }
  }
}

.atp-history {
  display: block;
  position: relative;
  height: 100%;

  .atp-dynamic-form__title {
    display: none;
  }

  &__table {
    &-column {
      &-header {
        &_action {
          width: 24px;
        }
      }

      &_action {
        display: flex;
        align-items: center;
      }

      .atp-dynamic-table-full-crud__link:disabled {
        color: #333333 !important;
      }
    }
  }
}
