.atp-table-actions-submenu {
  display: block;
}

.atp-table-actions-menu {
  display: block;

  &__open-button {
    &_single {
      opacity: 0.2;
    }

    &_multiple {
      background-color: rgba(82, 143, 0, 0.1);
    }

    &_other {
      min-width: 40px !important;
      padding: 0 8px !important;

      .mat-icon {
        margin-right: 0 !important;
      }
    }
  }
}
