.login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .atp-preloader-container {
    background-color: rgba(#f5f5f5, 0.5);

    &_transparent {
      opacity: 0;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 28px 71px rgba(0, 0, 0, 0.08);
    border-radius: 21px;
    padding: 40px 88px;
    width: 100%;
    max-width: 540px;
    max-height: 90vh;
    transition: opacity 0.3s linear;

    @media screen and (max-width: 450px) {
      max-height: 97vh;
      max-width: 97vw;
      padding: 16px 16px 32px;
    }
  }

  &__content {
    width: 100%;
    flex-grow: 1;
    overflow: hidden;
  }

  &__logo {
    display: flex;
    justify-content: center;
    padding: 16px 0px;

    @media screen and (max-width: 450px) {
      padding: 8px 0;
    }
  }

  &__header {
    font-weight: bold;
    font-size: 36px;
    line-height: 45px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    color: #333333;
    margin-bottom: 16px;

    @media screen and (max-width: 450px) {
      margin-bottom: 8px;
      font-size: 28px;
      line-height: 32px;
    }
  }

  &__text {
    text-align: center;
    color: #bdbdbd;

    @media screen and (max-width: 450px) {
      margin-bottom: 8px;

      br {
        display: none;
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
  }

  &__actions + &__actions {
    margin-top: 16px;
  }

  &__forms {
    margin-top: 20px;
    margin-bottom: 16px;

    @media screen and (max-width: 450px) {
      margin-top: 8px;
      margin-bottom: 8px;
    }

    .mat-form-field + .mat-form-field {
      margin-top: 5px;
    }
  }

  &__button {
    flex-shrink: 1;
    width: 100%;

    &_normal {
      padding-left: 42px;
      padding-right: 42px;
      width: auto;
      flex-shrink: 0;
    }

    &_bold {
      font-weight: 600;
    }
  }

  &__button + &__button {
    margin-left: 8px;
  }

  &__link {
    color: map-get($map: $primary-pallete, $key: 500);
  }
}

@import "./authorization/authorization.component";
@import "./registration/registration.component";
@import "./confirm-email/confirm-email.component";
@import "./password-recovery/password-recovery.component";
