.atp-dialog-container {
  & > * {
    width: 80vw !important;
    width: 100%;
    height: 100% !important;

    @media screen and (max-width: 1024px) {
      max-width: 90vw !important;
    }
  }

  .atp-dynamic-form {
    &__container {
      padding: 28px 32px 0;
      height: 100vh;
      overflow-y: auto;

      @media screen and (max-width: 750px) {
        padding: 8px 8px 0;
      }
    }

    &__shit {
      display: flex;
      align-items: center;
      position: absolute;
      top: 28px;
      //left: 0;
      transform: rotate(-90deg) translateX(-100%) translateY(-100%);
      transform-origin: 0 0 0;
      background-color: #73b600;
      padding: 5.21px 15px 9.71px 14px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.24px;
      color: #f5f5f5;
      border-radius: 3px 0px 0px 3px;
      cursor: pointer;

      @media screen and (max-width: 450px) {
        padding: 1px 8px 3px;
      }

      .mat-icon {
        margin-left: 11px;
        color: rgba(#f5f5f5, 0.5);
      }
    }
  }
}

.atp-dynamic-form {
  &__shit {
    display: none;
  }

  &__container {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0;
  }

  &__title {
    flex-shrink: 0;
    padding-bottom: 24px;

    .atp-title__text {
      margin: 0;
      font-weight: 600;
      font-size: 28px;
      line-height: 35px;
      letter-spacing: -0.24px;
      color: $custom-dark-primary-text;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    //height: 100%;
  }

  &__content {
    display: inline-flex;
    flex-direction: column;
    //overflow-y: auto;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
    border-radius: 5px;
    margin: 3px 3px 8px;
    width: calc(100% - 6px);
    //max-height: 100%;
    padding: 32px 40px;
    background-color: white;

    .mat-ink-bar {
      height: 2px;
      transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    textarea {
      min-height: 100px;
    }

    &_full-height {
      height: 100%;
    }
  }

  &__form-field.mat-form-field {
    display: block;
    width: 100%;
    background-color: inherit;
  }

  &__form-field {
    flex-shrink: 0;
    background-color: inherit;

    &_checkbox {
      margin-bottom: 19px;
    }

    &_multiple-file {
      max-width: none;

      .mat-form-field-flex {
        padding: 0 !important;
      }

      .mat-form-field-infix {
        padding-top: 0;
      }

      .mat-form-field-outline {
        &-start,
        &-end {
          border: 0 !important;
        }
      }
    }
  }

  &__form-field + &__form-field {
    margin-top: 8px;
  }

  &__group.ng-animating {
    overflow: hidden;
  }

  &__group {
    background-color: inherit;
    flex-shrink: 0;
    width: 100%;
    max-width: 648px;

    .atp-dynamic-form__form-field.mat-form-field {
      display: block;
      width: 100%;
      background-color: inherit;
    }

    &-fieldset {
      padding: 0 22px 12px;
      margin: 5px 0 24px;
      border: 1px solid #d3d9de;
      border-radius: 3px;
      background-color: inherit;

      &-container {
        position: relative;
        padding-top: 37px;
        background-color: inherit;
      }

      &-label {
        position: absolute;
        top: -0.65em;
        left: -14px;
        padding: 0 14px;
        font-size: 18px;
        line-height: 23px;
        font-weight: 600;
        letter-spacing: -0.24px;
        color: #333333;
        background-color: inherit;
      }
    }

    &_no-heading {
      padding: 0;
    }
  }

  &__actions {
    display: flex;
    flex-shrink: 0;
    //margin: auto -32px 0;
    padding: 11px 0px;
    //width: calc(100% + 64px);
    //box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.09);
    z-index: 34;

    &-button + &-button {
      margin-left: 12px;
    }
  }

  &__tab {
    &-group {
      background-color: inherit;

      .mat-tab-body-wrapper {
        background-color: inherit;

        .mat-tab-body {
          background-color: inherit;

          .mat-tab-body-content {

            //изменил
            // overflow: hidden;
            overflow: auto;

            .detail-lot__ag-grid {
              margin-top: 0;
            }

            background-color: inherit;
            padding-top: 4px;
          }
        }
      }

      &_root {
        height: 100%;

        &--custom {
          .mat-tab-body-wrapper {

            .mat-tab-body {
              .mat-tab-body-content {
                overflow-y: auto;
              }
            }
          }
        }

        .mat-tab-body-wrapper {
          height: 100%;

          .mat-tab-body {
            height: 100%;
            overflow-y: auto;

            .mat-tab-body-content {
              //изменил
              // overflow-y: auto;

              height: 100%;
              min-height: 100%;
              padding-top: 4px;
            }
          }
        }
      }
    }

    &-item-content {
      background-color: inherit;
      height: auto;
    }
  }
}

.atp-dialog-container {
  .atp-dynamic-form {
    &__form {
      //height: 100%;
    }
  }
}

.atp-dynamic-form__group-fieldset.atp-dynamic-form__group-fieldset_no-heading {
  border: none;
  padding: 0;
  margin: 0;

  .atp-dynamic-form__group-fieldset-container {
    padding: 0;
  }
}

.atp-dynamic-form__group-fieldset .atp-dynamic-form__group-fieldset.atp-dynamic-form__group-fieldset_no-heading {
  padding: 0;
  margin: 0;
}

atp-add-edit-popup-dynamic-table-full-crud,
atp-add-edit-mat-popup-dynamic-table-full-crud {
  display: block;
  position: relative;
  height: 100%;
}
