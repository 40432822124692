// Default
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
}

// Links
a {
  text-decoration: none;

  &:active,
  &:hover {
    outline: 0;
  }
}

// List
ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

// Headlines
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

// Default
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

:focus {
  outline: 0;
}

img,
audio,
video {
  max-width: 100%;
  height: auto;
}

audio,
canvas,
iframe,
video,
img,
svg {
  vertical-align: middle;
}

iframe {
  border: 0;
}

// From
textarea {
  resize: none; /*remove the resize handle on the bottom right*/
  overflow: auto;
  vertical-align: top;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

input,
textarea,
select,
button {
  outline: none;
  border: none;
  font-size: 100%;
  margin: 0;
  background-color: transparent;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  button:active span,
  button:focus span {
    position: relative;
    top: 0;
    left: 0;
  }
}

button,
input {
  line-height: normal;
}

input::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

// Table
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

td,
th {
  padding: 0;
  text-align: left;
}

button {
  display: block;
  background-color: transparent;
  padding: 0;
  color: unset;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}
