.unit-accesses {
  display: block;
  height: 100%;
  padding-bottom: 15px;
  max-width: 1040px;

  &__node {
    border: 1px solid #d3d9de;
    padding: 7px 16px 7px 0;
  }

  &__node + &__node {
    border-top: 0;
  }

  &__plug {
    width: 19px;
  }

  &__item {
    display: flex;
    align-items: center;
    min-height: 47px;
    width: 100%;

    &-body {
      width: 100%;
    }

    &-checkbox {
      margin-left: 30px;
    }
  }

  &__select {
    margin-left: 16px;
    max-width: 541px;

    .mat-form-field-wrapper {
      margin: 0;
      padding-bottom: 0;
    }
  }

  &__parent-button {
    margin-left: 8px !important;
  }
}
