.main {
  display: block;
  width: 100%;
  height: 100%;

  &__body {
    height: 100%;
    width: 100%;
  }

  &__menu-panel {
    .mat-drawer-inner-container {
      overflow: hidden !important;
    }
  }

  &__content {
    display: flex !important;
    justify-content: center;
    padding-top: 24px;
    padding-left: 16px;
    padding-right: 24px;

    @media screen and (max-width: 1024px) and (min-width: 1000px) {
      padding-top: 30px;
      padding-left: 30px;
      padding-right: 30px;
    }

    @media screen and (max-width: 1000px) {
      padding-left: 24px;
    }

    @media screen and (max-width: 750px) {
      padding-top: 16px;
      padding-left: 16px;
      padding-right: 16px;
    }

    @media screen and (max-width: 450px) {
      padding-top: 8px;
      padding-left: 8px;
      padding-right: 8px;
    }

    &-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: $max-width;
      height: 100%;
    }

    &_transition {
      transition: margin-left 0.2s linear;
    }
  }

  &__header {
    display: flex;
    padding-bottom: 14px;
    border-bottom: 1px solid #cecece;
    justify-content: space-between;
    flex-shrink: 0;

    @media screen and (max-width: 750px) {
      flex-direction: column-reverse;
    }

    &-text {
      @media screen and (max-width: 750px) {
        width: 100%;
      }

      @media screen and (max-width: 400px) {
        font-size: 26px;
        line-height: 30px;
      }

      @media screen and (max-width: 320px) {
        font-size: 24px;
        line-height: 28px;
      }
    }

    &-info {
      display: flex;
      align-items: center;
      flex-shrink: 0;

      @media screen and (max-width: 750px) {
        margin-left: auto;
        margin-bottom: 16px;
        max-width: calc(100% - 50px);
      }

      @media screen and (max-width: 450px) {
        margin-bottom: 8px;
      }
    }

    &-messages {
      margin-right: 30px;

      @media screen and (max-width: 450px) {
        margin-right: 8px;
      }

      &-button {
        border-radius: 3px !important;
        background-color: rgba(map-get($primary-pallete, 800), 0.1);
      }
    }

    &-logout {
      color: #bdbdbd;
    }

    &-person {
      margin-right: 24px;

      @media screen and (max-width: 450px) {
        margin-right: 8px;
      }

      &-fio {
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: -0.24px;
        color: $custom-dark-primary-text;
        margin-bottom: 4px;

        @media screen and (max-width: 450px) {
          font-size: 16px;
          line-height: 18px;
        }

        @media screen and (max-width: 320px) {
          font-size: 14px;
          line-height: 16px;
        }
      }

      &-email {
        @media screen and (max-width: 450px) {
          font-size: 14px;
          line-height: 18px;

          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 180px;
        }

        @media screen and (max-width: 320px) {
          font-size: 12px;
          line-height: 16px;
          max-width: 150px;
        }
      }
    }
  }

  &__router {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    height: 100%;

    & > router-outlet {
      width: 0;
    }

    & > * {
      width: 100%;
    }
  }

  &__open-menu-button {
    min-width: 0 !important;
    width: 40px;
    height: 40px;
    padding: 0 !important;
    margin-bottom: 26px !important;

    .mat-icon {
      margin-right: 0 !important;
      margin-top: -4px;
    }

    @media screen and (min-width: 1025px) {
      display: none !important;
    }

    @media screen and (max-width: 1000px) {
      margin-bottom: 16px !important;
    }

    @media screen and (max-width: 750px) {
      position: absolute !important;
      margin-bottom: 0 !important;
      top: 19px;
    }

    @media screen and (max-width: 450px) {
      top: 8px;
    }
  }
}

@import "./notifications-popup/notifications-popup.component";
