.subscription-section-products {
  display: block;
  position: relative;

  .atp-dynamic-form__form{

  }

  .atp-dynamic-form__content {
    padding-top: 24px;
    height: inherit;
    overflow: auto;
  }


  .atp-dynamic-form__container .atp-dynamic-form__form {
    height: auto;
  }
}

//изменил




.atp-dynamic-form__container {
  .management-offers__ag-grid-container {
    // height: 100%;
  }
  .atp-dynamic-form__form {
    height: 100%;
  }

  .management-offers__ag-grid-container {
    // height: 100%;
  }

  .atp-dynamic-form__form--content {
    height: auto;
  }

}


.atp-dynamic-form__form {

  // height: 100%;
  // height: auto;
  // flex: 1 1 auto;
}

// .atp-dynamic-form__container{
//   height: initial;
// }

@import "./sections-tree/sections-tree.component.scss";
