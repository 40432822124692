.atp-upload-file {
  display: block;
  width: 100%;

  &__container {
    position: relative;
    min-height: 6rem;
    height: fit-content;
    width: 100%;
  }

  &__dragndrop-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    border: 2px dashed #e0e0e0;
    height: 138px;

    &-info {
      display: flex;
      align-items: center;
    }

    &-image {
      margin-right: 25px;

      @media screen and (max-width: 600px) {
        display: none;
      }
    }

    &-description {
      &-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: -0.24px;
        color: $custom-dark-primary-text;
        margin-bottom: 7px;
      }

      &-text {
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.24px;
        color: #bdbdbd;

        &_selected {
          color: map-get($map: $primary-pallete, $key: 500);
        }

        &_error {
          color: map-get($map: $warn-pallete, $key: 500);
        }
      }
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &-item {
    display: inline-block;

    &__main {
      position: relative;
      display: inline-flex;
      align-items: center;
      padding-right: 40px;
    }

    &__link {
      margin-bottom: 6px;
    }

    &__name {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: $custom-dark-primary-text;
    }

    &__date {
      font-size: 16px;
      line-height: 20px;
      color: $custom-dark-primary-text;
      margin-right: 12px;
    }

    &__error {
      font-size: 16px;
      line-height: 20px;
      color: map-get($map: $warn-pallete, $key: 500);
    }

    &__size {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.24px;
      color: #bdbdbd;
      margin-left: 12px;
    }

    &__remove-button {
      position: absolute !important;
      right: 0;
      width: 32px !important;
      height: 32px !important;
      line-height: 32px !important;
      display: none !important;
      transition: opacity 0.3s linear;
    }

    &__progress-bar {
      padding-right: 40px;

      .mat-progress-bar {
        height: 8px;
        border-radius: 4px;
      }
    }
  }

  &-item:hover {
    .atp-upload-file-item__remove-button {
      display: inline-block !important;
    }

    .atp-upload-file-item__remove-button:hover {
      opacity: 0.7;
    }
  }

  &-item:first-child {
    margin-top: 16px;
  }

  &-item + &-item {
    margin-top: 8px;
  }
}
