.lot-templates-table {
  display: block;
  height: 100%;
}

.atp-dynamic-form__container_lottemplates {
  min-width: 40vw;

  .atp-dynamic-form__content {
    height: 100%;
  }
}

.add-edit-lot-template {
  display: block;
  position: relative;
}

@import "./add-edit-data-lot-templates/add-edit-data-lot-templates.component.scss";
