.atp-notifications {
  display: block;
  overflow: hidden;
  height: 100%;
  padding: 24px;
}

.atp-notification {
  position: relative;
  display: block;
  width: 414px;
  font-size: 16px;
  line-height: 20px;
  color: #616161;
  background-color: white;
  border-radius: 3px;
  z-index: 1;

  .atp-notification__data {
    min-height: 100px;
    background-color: rgba(#e0e0e0, 0.5);
    padding: 16px;
  }

  .atp-notification__header {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 15px;
    padding-right: 40px;
    box-sizing: border-box;

    &-text {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      text-align: left;
      color: #333333;
    }
  }

  &__close-button {
    position: absolute !important;
    top: -12px;
    right: 0;
    color: #bdbdbd;
  }

  &__date {
    margin-top: 4px;
    text-align: end;
  }
}

.atp-notification + .atp-notification {
  margin-top: 8px;
}
