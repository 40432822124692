.step-control {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding-top: 4px;

  &__value {
    flex-grow: 1;
    font-size: 14px;
  }

  &__button.mat-icon-button {
    height: 18px;
    width: 18px;

    .mat-icon {
      line-height: 18px;
      font-size: 18px;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    margin-right: 6px;
  }
}
