.add-edit-lot-custom-offer-columns {
  display: block;
  max-width: 648px;

  &__warn {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #616161;
    margin-bottom: 16px;

    span {
      color: map-get($map: $primary-pallete, $key: 800);
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.154px;
      margin-right: 10px;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    padding-right: 24px;
    color: $custom-dark-primary-text;

    &-container {
      display: flex;
      align-items: center;
      min-height: 42px;
      margin-bottom: 4px;
    }
  }

  &__add-button {
    .mat-icon {
      margin-right: 8px;
    }

    &-container.ng-animating {
      overflow: hidden;
    }
  }

  &__item {
    &-container {
      display: flex;
      width: 100%;
    }

    &-checkbox {
      margin-top: 14px;
      margin-right: 15px;
      align-self: flex-start;
    }

    &-actions {
      display: flex;
      flex-direction: column;
      padding-top: 4px;
      padding-left: 12px;

      .mat-icon-button {
        width: 24px;
        height: 24px;
        line-height: 24px;
      }
    }
  }

  &__item.ng-animating {
    overflow: hidden;
  }
}
