globaz-check {
    display: block;
    height: 100%;
    max-width: 1000px;
    width: 100%;

    .globaz-check__info-row {
        color: #000;
        font-weight: 400;
        font-size: 16px;

        span {
            color: #657195;
            font-weight: 500;
            font-size: 14px;
        }
    }

    .globaz-check__info-row + .globaz-check__info-row {
        margin-top: 5px;
    }

    .globaz-check__classifier-list {
        margin: 5px 0 5px 20px;

        div + div {
            margin-top: 5px;
        }
    }

    .globaz-check__company-actions {
        margin: 20px 0;
    }
}
