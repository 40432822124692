.error404 {
  display: block;
  width: 100%;

  &__container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -15%;
  }

  &__title {
    font-size: 86px;
    line-height: 108px;
    letter-spacing: -0.154px;
    color: #d3d9de;
    margin-bottom: 16px;
    font-weight: bold;
  }

  &__subtitle {
    font-weight: 600;
    font-size: 26px;
    line-height: 33px;
    letter-spacing: -0.24px;
    color: $custom-dark-primary-text;
    margin-bottom: 24px;
  }

  &__text {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #bdbdbd;
    margin-bottom: 32px;
  }

  &__actions {
  }
}
