.atp-actions-popup {
  display: block;
  padding: 80px 72px;

  @media screen and (max-width: 450px) {
    padding: 32px 16px;
  }

  &__title {
    font-weight: bold;
    font-size: 36px;
    line-height: 45px;
    padding-bottom: 16px;
    letter-spacing: -0.02em;
    color: #333333;
    text-align: center;

    @media screen and (max-width: 450px) {
      font-size: 32px;
      line-height: 36px;
    }
  }

  &__content {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #bdbdbd;
    text-align: center;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 16px 0;

    &-button {
      min-width: 100px;

      @media screen and (max-width: 450px) {
        padding: 0 !important;
      }

      &_cancel {
        flex-grow: 0.5;
      }

      &_confirm {
        flex-grow: 1;
      }
    }

    &-button + &-button {
      margin-left: 8px;
    }
  }
}
