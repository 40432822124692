@import "./globaz-check/globaz-check.component";
@import "./globaz-sections/globaz-sections.component";

.users {
  display: block;
  height: 100%;
}

.participants-profile {
  display: block;
  position: relative;
  height: 100%;
}

.users-list {
  display: block;
}

.user-profile-button {
  display: inline-block;
  color: map-get($map: $primary-pallete, $key: 500);
  cursor: pointer;
}

.user-globaz-check-button {
  display: inline-block;
  color: map-get($map: $primary-pallete, $key: 500);
  cursor: pointer;
}
