.message-legend {
  display: block;
  overflow: hidden;
  background-color: inherit;
  width: 100%;
  max-width: 648px;

  &__item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
  }
}
