.authorization {
  display: block;

  &__registration {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__recovery-password {
    color: #bdbdbd;
    transition: opacity 0.2s linear;
  }

  &__recovery-password:hover {
    opacity: 0.5;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    @media screen and (max-width: 450px) {
      margin-top: 12px;
    }
  }

  &__doc-link {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.02em;
    color: #616161;
    margin-bottom: 4px;
    transition: opacity 0.2s linear;
  }

  &__doc-link:hover {
    opacity: 0.5;
  }

  &__company {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #bdbdbd;
    text-align: center;
    user-select: none;
  }

  &__submit {
    width: 100%;
    margin-bottom: 24px !important;

    @media screen and (max-width: 450px) {
      margin-bottom: 8px !important;
    }

    &-icon {
      margin-bottom: 4px;
      margin-left: 8px;
    }
  }
}
