.stage-users-checkboxes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .mat-checkbox {
    margin-bottom: 16px;

    .mat-checkbox-layout {
      white-space: normal;
    }
  }

  &__autocompletes {
    display: flex;

    &-user {
      width: 250px;
    }

    &-time {
      width: 200px;
    }

    &-actions {
      display: flex;
      margin-top: 7px;
      width: 80px;
      flex-shrink: 0;
      justify-content: flex-end;
    }
  }
}
