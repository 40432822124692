.atp-dialog-container > .notifications-popup {
  width: auto !important;
}

.notifications-popup {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px;
  height: 100vh;

  &__title {
    margin-bottom: 24px;
  }

  .atp-notifications {
    display: block;
    overflow-y: auto;
    flex-grow: 1;
    padding: 0;
  }
}
