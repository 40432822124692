.atp-dialog-container {
  .detail-lot {
    .atp-dynamic-form__title {
      display: flex;
    }

    &__title {
      padding-top: 0;

      .atp-dynamic-form__title {
        margin-right: 66px;
      }
    }
  }
}

.detail-lot {
  display: block;
  position: relative;
  //изменил
  overflow: hidden;

  .atp-dynamic-form__tab-item-content > .atp-dynamic-form__group {
    @media screen and (max-width: 750px) {
      padding: 24px 24px 0;
    }

    @media screen and (max-width: 450px) {
      padding: 16px 16px 0;
    }

    .atp-dynamic-form__group-fieldset {
      .atp-dynamic-form__group-fieldset-container {
        @media screen and (max-width: 750px) {
          padding-top: 24px;
        }
      }
    }
  }

  &__ag-grid {
    display: block;
    width: calc(100% - 6px);
    height: calc(100% - 9px);
    padding: 0 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
    background-color: white;
    border-radius: 3px;
    margin: 3px 3px 6px;

    //изменения
    // overflow: auto;


    .ag-cell {
      white-space: normal !important;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-shrink: 0;
    padding-top: 24px;
    padding-bottom: 24px;
    margin-top: -16px;

    @media screen and (max-width: 750px) {
      padding-bottom: 16px;
    }

    @media screen and (max-width: 600px) {
      padding-bottom: 8px;
    }

    @media screen and (max-width: 450px) {
      padding-bottom: 4px;
    }

    .atp-dynamic-form__title {
      display: none;
      width: auto;
      padding-bottom: 0;
      margin-top: 16px;

      @media screen and (max-width: 450px) {
        margin-top: 8px;
      }
    }

    &-actions {
      margin-top: 16px;
      margin-left: auto;

      @media screen and (max-width: 450px) {
        margin-top: 8px;
      }

      &-button {
        @media screen and (max-width: 450px) {
          line-height: 30px !important;
          padding: 0 12px !important;
        }
      }
    }
  }

  &__technical-items {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: -24px;

    @media screen and (max-width: 450px) {
      margin-left: -16px;
    }

    @media screen and (max-width: 400px) {
      margin-left: -8px;
    }

    .lot-technical-item {
      margin-left: 24px;
      margin-top: 16px;

      @media screen and (max-width: 450px) {
        margin-top: 8px;
        margin-left: 16px;
      }

      @media screen and (max-width: 400px) {
        padding: 3px 8px;
        margin-left: 8px;
      }
    }
  }

  &__shared-info {
    &-row {
      display: flex;
      padding: 16px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      @media screen and (max-width: 750px) {
        padding: 8px 0;
      }
    }

    &-row:last-child {
      border-bottom: 0;
    }

    &-header {
      flex-shrink: 0;
      width: 227px;
      font-size: 16px;
      line-height: 20px;
      color: #616161;

      @media screen and (max-width: 600px) {
        width: calc(50% - 16px);
        font-size: 14px;
        line-height: 18px;
      }
    }

    &-value {
      padding-left: 32px;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.24px;
      color: $custom-dark-primary-text;

      @media screen and (max-width: 600px) {
        font-size: 16px;
        line-height: 20px;
        padding-left: 16px;
      }

      &_date {
        color: map-get($map: $primary-pallete, $key: 800);
      }
    }
  }

  &__document {
    margin-bottom: 6px;

    &-name {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: $custom-dark-primary-text;
    }

    &-size {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.24px;
      color: #bdbdbd;
      margin-left: 12px;
    }

    &s {
      padding: 24px 0;
    }
  }

  &__document + &__document {
    margin-top: 24px;
  }

  .atp-dynamic-form__actions {
    display: none;
  }
}
