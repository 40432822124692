.atp-dynamic-table-full-crud {
  &__filters-panel {
    margin-right: 8px;
  }

  &__control-panel {
    padding: 32px 0 24px;

    &-line {
      display: flex;
      align-items: center;

      &_tree {
        display: flex;
        justify-content: space-between;
        padding-top: 24px;
      }
    }
  }

  &__trees-group-toggle {
    margin-left: auto;
  }

  &__back-button {
    margin-right: 26px !important;
    flex-shrink: 0;
  }

  &__action-buttons {
    margin-left: auto;
    display: flex;
    align-items: center;
    flex-shrink: 0;

    &-add {
      margin-right: 3px !important;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    background-color: white;
  }

  &__table-container {
    width: calc(100% - 4px);
    margin: 0 2px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
    border-radius: 3px;
    background-color: white;
    padding: 0 8px;

    &_multiple-selected{
      .atp-dynamic-table-full-crud__actions{
        position: sticky;
        bottom: 0;
        z-index: 2;
      }
    }
  }

  &__table-scroll-container {
    overflow: auto;
    transition: max-height 0.1s linear;
  }

  &__table {
    &-disclaimer {
      vertical-align: middle;
    }

    th.atp-dynamic-table-full-crud__table-actions-column {
      width: 88px;
    }

    th.atp-dynamic-table-full-crud__table-actions-column_single {
      width: 44px;
    }

    th.atp-dynamic-table-full-crud__table-actions-column_empty {
      width: 24px;
      padding: 0 !important;
    }

    td.atp-dynamic-table-full-crud__table-actions-column {
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 0;
    }

    th.mat-header-cell {
      border-bottom-color: #d3d9de80;
    }

    td.mat-cell {
      border-bottom-color: #d3d9de80;
    }

    [mat-sort-header].cdk-program-focused .mat-sort-header-container {
      border-bottom: 0;
    }

    .cdk-cell {
      padding: 16px 12px;
      vertical-align: middle;
    }

    .cdk-header-cell {
      vertical-align: bottom;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: $custom-dark-primary-text;
      padding: 22px 12px 10px;
    }

    .mat-sort-header-content {
      text-align: start;
    }

    &-actions-column {
      &-external-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &-checkbox {
        font-size: 0;
      }

      &-marker{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 15px;
      }
    }
  }

  &__breadcrumbs {
    display: flex;
    flex-direction: row-reverse;

    &-item {
      padding: 0 !important;
      min-width: 0 !important;
      color: #bdbdbd !important;
      font-size: 15px;
      line-height: 16px !important;

      .mat-button-focus-overlay {
        background: transparent !important;
      }
    }

    &-item.mat-button-disabled {
      color: $custom-dark-primary-text !important;
    }

    &-separator {
      margin: 0 7px;
    }
  }
}

.atp-dynamic-table-full-crud-tree-link {
  display: flex;
  align-items: center;

  a.mat-button,
  button.mat-button {
    padding: 0 !important;
    line-height: 1.4em !important;
    text-align: left;
    color: #73b600 !important;
  }

  &__icon {
    margin-right: 8px;
  }
}
