@import "components/atp-title/atp-title.component";
@import "components/atp-actions-popup/atp-actions-popup.component";
@import "components/atp-range-date-time-picker/atp-range-date-time-picker.component";
@import "components/atp-upload-file/atp-upload-file.component";
@import "components/atp-table-actions-menu/atp-table-actions-menu.component";
@import "atp-dynamic-table-full-crud/atp-dynamic-form/atp-dynamic-form.component";
@import "atp-dynamic-table-full-crud/atp-dynamic-table-full-crud.component";
@import "atp-dynamic-table-full-crud/atp-filters-panel/atp-filters-panel.component";
@import "atp-dynamic-table-full-crud/atp-movement-tree/atp-movement-tree.component";
@import "atp-dynamic-table-full-crud/atp-history/atp-history.component";
@import "atp-dynamic-table-full-crud/atp-history-item-detail/atp-history-item-detail.component";
@import "atp-notifications/atp-notifications.component";

.mat-dialog-container {
  padding: 0 !important;
  height: auto !important;
}

.atp-dialog-container {
  background-color: #f5f5f5;
  display: block;
  box-sizing: border-box;

  // overflow: auto;
  outline: 0;
  min-width: 450px;

  // Since the dialog won't stretch to fit the parent, if the height
  // isn't set, we have to inherit the min and max values explicitly.
  min-height: inherit;
  max-height: inherit;
}

.atp-preloader-container {
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100000000;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 100vh;
}

.atp-preloader-container_transparent {
  background-color: transparent;
}

.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
  font-size: 14px !important;
}
