.lot-templates {
  display: block;
  position: relative;

  &__item {
    width: 286px;
    padding: 46px 34px !important;
    margin-left: 16px !important;
    margin-top: 16px !important;
    cursor: pointer;

    &s {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      margin-top: -4px;
      margin-left: -16px;
    }

    &-name {
      white-space: break-spaces;
      margin-bottom: 11px;
    }

    &-description {
      white-space: break-spaces;
      font-size: 16px;
      line-height: 20px;
      color: #bdbdbd;
    }
  }
}
