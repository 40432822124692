.working-days {
  display: block;

  &__header {
    font-size: 12px;
    margin-bottom: 12px;
  }

  &__button {
    display: block;
    margin-bottom: 20px;
  }
}
