.ag-mat-editor-renderer,
.ag-mat-input-renderer,
.ag-mat-input-number-renderer,
.ag-mat-select-renderer,
.ag-mat-input-step-number-renderer,
.ag-add-edit-lot-product-renderer,
.ag-add-edit-lot-header-actions-renderer,
.ag-add-edit-lot-actions-renderer,
.ag-add-edit-lot-header-other-column-renderer,
.ag-add-edit-lot-footer-actions-renderer,
.ag-offers-product-renderer,
.ag-offers-price-renderer,
.ag-management-offers-header-user-group-renderer,
ag-management-offers-select-winner-renderer,
ag-management-offers-header-select-winner-renderer,
.ag-history-info-cell-renderer,
.ag-input-plug-renderer {
  display: block;
}

.ag-mat-editor-renderer {
  padding: 10px;
}

.ag-history-info-cell-renderer {
  white-space: normal;
}

.ag-history-specification-renderer {
  display: flex;

  &__container + &__container {
    margin-left: 6px;
  }

  &__block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 3px;

    &_column {
      flex-wrap: nowrap;

      .ag-history-specification-renderer__block {
        margin-top: -2px;
      }
    }
  }

  &__property {
    margin-right: 3px;
  }

  &__header {
    font-weight: bold;
    margin-right: 3px;

    &_columns {
      margin-top: 3px;
    }
  }
}

.ag-offers-price-renderer:not(.ag-offers-price-renderer_not-plug) {
  position: relative;
  display: flex;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  padding: 0 10px 0 10.5px;

  .ag-offers-price-renderer__value {
    white-space: nowrap;
    overflow: hidden;
    margin-top: -0.2em;
    font-size: 14px;
    padding-top: 13px;
  }

  .ag-offers-price-renderer__error {
    position: absolute;
    bottom: -1.4em;
    left: 0;
    color: map-get($map: $warn-pallete, $key: 500);
    font-size: 10px;
  }
}

.ag-offers-price-renderer:not(.ag-offers-price-renderer_not-plug)::before {
  content: "";
  position: absolute;
  border: 1px solid #d3d9de;
  border-radius: 3px;
  top: 0;
  left: 0;
  right: 0;
  height: 39px;

  &__error{
    border-color: map-get($map: $warn-pallete, $key: 500);
  }
}

.ag-offers-price-renderer__error:not(.ag-offers-price-renderer_not-plug)::before {
  border-color: map-get($map: $warn-pallete, $key: 500);
}

.ag-input-plug-renderer,
.ag-mat-input-step-number-renderer {
  position: relative;
  display: flex;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  padding: 0 10px 0 10.5px;

  &::before {
    content: "";
    position: absolute;
    border: 1px solid #d3d9de;
    border-radius: 3px;
    top: 0;
    left: 0;
    right: 0;
    height: 39px;
  }

  &__value {
    white-space: nowrap;
    overflow: hidden;
    margin-top: -0.2em;
    font-size: 14px;
    padding-top: 13px;
  }

  &_select {
    cursor: pointer;
    padding-right: 32px;

    .ag-input-plug-renderer__value {
      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: 17px;
        right: 10px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid;
        margin: 0 4px;
      }
    }
  }

  &__error {
    position: absolute;
    bottom: -1.4em;
    left: 0;
    color: map-get($map: $warn-pallete, $key: 500);
    font-size: 10px;
  }
}

.ag-grid-cell-invalid {
  .ag-input-plug-renderer {
    &::before {
      content: "";
      border-color: map-get($warn-pallete, 500);
    }

    &_select {
      .ag-input-plug-renderer__value {
        &::after {
          border-top-color: map-get($warn-pallete, 500);
        }
      }
    }
  }
}

.ag-add-edit-lot-footer-actions-renderer__button {
  .mat-icon {
    margin-top: -4px;
    margin-right: 4px;
  }
}

.ag-add-edit-lot-header-actions-renderer__button {
  margin-left: -2px !important;
}

.ag-add-edit-lot-product-renderer__container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.ag-add-edit-lot-header-other-column-renderer {
  width: 100%;

  .mat-form-field-suffix {
    top: 0 !important;
  }

  &__actions {
    display: flex;
    align-items: center;

    .mat-checkbox-inner-container {
      margin-right: 9px !important;
    }
  }
}

.ag-mat-form-field-input,
.ag-mat-form-field-textarea {
  .mat-form-field-wrapper {
    margin: 0 !important;
  }

  &_without-error {
    .mat-form-field-wrapper {
      padding-bottom: 0 !important;
    }
  }
}

.ag-management-offers-header-user-group-renderer {
  position: relative;
  width: 100%;

  &__user {
    font-weight: 600;
    font-size: 14px;

    &-name {
      width: fit-content;
      color: map-get($primary-pallete, 800);
      line-height: 16px;
      cursor: pointer;
      transition: opacity 0.2s linear;
      margin-bottom: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -moz-box;
      -moz-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      line-clamp: 1;
      box-orient: vertical;
      white-space: normal;
      max-height: 16px;
    }

    &-name:hover {
      opacity: 0.5;
    }

    &-company {
      line-height: 18px;
      color: rgba(#616161, 0.5);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -moz-box;
      -moz-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      line-clamp: 1;
      box-orient: vertical;
      white-space: normal;
      max-height: 18px;
    }
  }

  &__actions {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: none;
    align-items: center;
  }

  &__winner-text {
    font-weight: bold;
  }
}

.ag-management-offers-header-user-group-renderer:hover {
  .ag-management-offers-header-user-group-renderer__actions {
    display: flex;
  }
}
