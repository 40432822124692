.atp-history-item-detail {
  display: block;
  height: 100%;
  position: relative;

  &__container {
    height: 100%;
    padding: 20px;
  }

  &__title {
    display: flex;
    align-items: center;
    padding-bottom: 10px;

    .atp-title__text {
      font-size: 20px;
    }
  }

  &__content {
    overflow: auto;
    max-height: calc(100% - 68px);
  }

  &-table {
    &__cell {
      padding: 6px;
      text-align: left;

      &_first {
        width: 35%;
      }

      &_second {
        width: 65%;
      }

      &_partial-link.atp-history-item-detail-table__cell_first {
        text-align: right;
        vertical-align: top;
      }
    }
  }

  &-partial-link {
    &__header-row {
      text-align: left;
    }

    &__cell {
      &_first {
        width: 50%;
      }

      &_second {
        width: 50%;
      }
    }
  }

  &__actions {
    padding: 15px 0 0 0;
    height: 51px;
    display: flex;
    bottom: 0;
    width: 100%;
    z-index: 34;

    button + button {
      margin-left: 20px;
    }
  }
}
