.atp-dynamic-form__tab-item-content_tabletab {
  height: calc(100% - 9px);

  .atp-dynamic-form__form-field_order {
    height: 100%;
  }
}

.add-edit-lot-table {
  display: block;
  position: relative;
  height: 100%;

  ag-grid-angular {
    width: 100%;
    // flex-grow: 1;

    .ag-cell {
      white-space: normal !important;
    }
  }

  &__error-text {
    margin-top: 7px;
    color: #f44336;
    font-size: 75%;
  }

  &__popup {
    &-container {
      width: 80vw;
      height: 100%;
      padding: 20px;

      @media screen and (max-width: 1024px) {
        width: 90vw !important;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: calc(100% - 6px);
      padding: 0 8px;
      box-shadow: 0px 2px 4px rgb(0 0 0 / 24%);
      background-color: white;
      border-radius: 3px;
      margin: 3px 3px 6px;

      &-actions {
        position: fixed;
        bottom: 0;
        right: 32px;
        display: flex;
        align-items: center;
        padding: 11px 0;

        &-button {
          color: map-get($map: $primary-pallete, $key: 800) !important;
        }
      }
    }
  }

  &__ag-grid {
    &-content {
      flex-grow: 1;
      width: 100%;
    }

    &-footer {
      width: 100%;
      height: 78px;
      flex-grow: 0;
    }
  }
}
