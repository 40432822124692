.sections-tree {
  height: 100%;
  max-height: 400px;

  .mat-checkbox-layout {
    white-space: normal !important;
  }

  .mat-button-disabled {
    display: none;
  }

  .mat-checkbox-inner-container {
    margin-left: 10px;
    margin-right: 18px !important;
    // 75
  }
}
